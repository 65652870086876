/* eslint-disable no-console */
/* eslint-disable react/prop-types */
// src/contexts/LanguageContext.js
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  // Read the language from localStorage or default to 'en'
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage || "en";
  });

  const switchLanguage = (lang) => {
    if (lang === "en" || lang === "fi") {
      setLanguage(lang);
    } else {
      console.warn("Unsupported language. Defaulting to English.");
      setLanguage("en");
    }
  };

  useEffect(() => {
    // Store the current language in localStorage whenever it changes
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
