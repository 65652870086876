import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#1e1e1e] text-white py-4 text-center">
      <p className="text-sm md:text-base">
        © {new Date().getFullYear()} IMR Consulting. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
