import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import Footer from "parts/Footer";
import Header from "parts/Header";

// Translation object
const translations = {
  en: {
    title: "Growing Your Business Is Our Calling",
    subtitle:
      "We provide developers & designers using the latest technologies to help you scale up your business.",
    developers: "Expert Developers",
    developersDesc:
      "Our developers are skilled in modern technologies like React, Node.js, and more to deliver robust solutions.",
    designers: "Creative Designers",
    designersDesc:
      "Our designers create visually appealing and user-friendly interfaces that elevate your brand.",
    support: "24/7 Support",
    supportDesc:
      "Our support team is always available to assist you, ensuring smooth operations and addressing any concerns.",
  },
  fi: {
    title: "Liiketoimintasi kasvattaminen on kutsumuksemme",
    subtitle:
      "Tarjoamme kehittäjiä ja suunnittelijoita, jotka käyttävät uusimpia teknologioita auttaaksemme sinua laajentamaan liiketoimintaasi.",
    developers: "Asiantuntija Kehittäjät",
    developersDesc:
      "Kehittäjämme ovat taitavia modernissa teknologiassa, kuten Reactissa, Node.js:ssä ja muussa, ja tuottavat vahvoja ratkaisuja.",
    designers: "Luovat Suunnittelijat",
    designersDesc:
      "Suunnittelijamme luovat visuaalisesti miellyttäviä ja käyttäjäystävällisiä käyttöliittymiä, jotka kohottavat brändiäsi.",
    support: "24/7 Tuki",
    supportDesc:
      "Tukitiimimme on aina valmiina auttamaan sinua, varmistaen sujuvat toiminnot ja vastaamalla kaikkiin huolenaiheisiisi.",
  },
};

const AboutUs = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  return (
    <>
      <Header />
      <div className="bg-gradient-to-r from-gray-800 to-gray-600 py-20 px-6 sm:px-8 lg:px-16">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-white mb-6">{t.title}</h1>
          <p className="text-xl text-gray-300 mb-12">{t.subtitle}</p>
          <div className="flex flex-wrap justify-center gap-8">
            {/* Developer Section */}
            <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-sm transform hover:-translate-y-2 transition-transform duration-300">
              <h2 className="text-2xl font-semibold text-white mb-4">
                {t.developers}
              </h2>
              <p className="text-gray-400">{t.developersDesc}</p>
            </div>
            {/* Designer Section */}
            <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-sm transform hover:-translate-y-2 transition-transform duration-300">
              <h2 className="text-2xl font-semibold text-white mb-4">
                {t.designers}
              </h2>
              <p className="text-gray-400">{t.designersDesc}</p>
            </div>
            {/* Support Section */}
            <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-sm transform hover:-translate-y-2 transition-transform duration-300">
              <h2 className="text-2xl font-semibold text-white mb-4">
                {t.support}
              </h2>
              <p className="text-gray-400">{t.supportDesc}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
