/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
import React, { useContext, useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { LanguageContext } from "context/LanguageContext";
import client from "api/client";
// import { FaStar, FaStarHalfAlt } from "react-icons/fa";

export default function Testimonial() {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessTestimonials",
      })
      .then((response) => {
        setData(response?.items);
      })
      .catch(console.error);
  }, []);

  return (
    <section className="container py-10 mx-auto px-6">
      <Fade direction="up">
        <span className="text-5xl text-black block text-center font-thin">
          {language === "fi" ? "Arvostelut" : "Client Testimonials"}
        </span>
      </Fade>
      <Fade direction="up" delay={500}>
        <p className="font-light text-lg text-gray-500 text-center mb-6">
          {language === "fi"
            ? "Mitä he sanoivat meistä."
            : "What they said about us."}
        </p>
      </Fade>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        className="mySwiper"
        loop
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        spaceBetween={50}
      >
        {data?.map((item, id) => (
          <SwiperSlide key={id}>
            <div className="bg-[#e9ecef] h-[100vh] p-4 md:h-[75vh] rounded-xl shadow-lg flex flex-col md:flex-row">
              <div className="bg-[#212529] relative w-full md:w-3/12 flex justify-center items-center">
                {/* Optional placeholder for an image or background */}
              </div>
              <div className="w-full md:w-2/3 pl-4 md:pl-10 flex flex-col justify-start mt-4 md:mt-[70px]">
                <h2 className="text-black text-4xl md:text-3xl font-bold">
                  {item?.fields?.fullName}
                </h2>
                <p className="font-light py-2 text-gray-500 mb-3">
                  {language === "fi"
                    ? item?.fields?.fiOccupation
                    : item?.fields?.enOccupation}
                </p>
                {/* <div className="flex py-2 items-center mb-4">
                  <FaStar className="text-black" />
                  <FaStar className="text-black" />
                  <FaStar className="text-black" />
                  <FaStar className="text-black" />
                  <FaStarHalfAlt className="text-black" />
                </div> */}
                <p className="font-light pr-0 md:pr-10 pt-5 leading-10 lg:leading-none text-lg text-gray-600">
                  {language === "fi"
                    ? item?.fields?.fiDescription
                    : item?.fields?.enDescription}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev text-black" />
        <div className="swiper-button-next text-black" />
      </Swiper>
    </section>
  );
}
