/* eslint-disable no-console */
/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
import React, { useContext, useEffect, useState } from "react";

import { Fade } from "react-awesome-reveal";

import { LanguageContext } from "context/LanguageContext";
import client from "api/client";

export default function HeroTeam() {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessTeams",
      })
      .then((response) => {
        setData(response?.items[0]?.fields);
      })
      .catch(console.error);
  }, []);
  return (
    <section className="hero sm:items-center lg:items-start sm:flex-row">
      <div className="w-full sm:w-1/2 flex flex-col px-5 mb-5 sm:mb-0 sm:px-12 sm:mt-6 lg:mt-6 xl:mt-20">
        <Fade direction="up" triggerOnce>
          <h1 className="text-6xl text-gray-900 font-bold leading-tight mb-5">
            {language === "fi" ? (
              <>{data?.fiHeading}</>
            ) : (
              <>{data?.enHeading}</>
            )}
          </h1>
        </Fade>
        <Fade direction="up" delay={500} triggerOnce>
          <p className="font-light text-xl text-gray-600 leading-relaxed">
            {language === "fi" ? <>{data?.fiDes}</> : <>{data?.enDes}</>}
          </p>
        </Fade>
      </div>
      <div className="flex pt-5 w-full justify-center items-center order-first md:w-full lg:order-last lg:w-1/2">
        <Fade direction="top-right" triggerOnce delay={300}>
          <img
            src={data?.teamImg?.fields?.file?.url}
            alt="Hero"
            className="filter grayscale"
          />
        </Fade>
      </div>
    </section>
  );
}
