/* eslint-disable no-console */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import client from "api/client";
import { LanguageContext } from "context/LanguageContext";
import React, { useContext, useEffect, useState } from "react";

import Fade from "react-awesome-reveal";

export default function AllTeam() {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessTeamMembers",
      })
      .then((response) => {
        setData(response?.items);
      })
      .catch(console.error);
  }, []);

  return (
    <section className="container py-20 mx-0 sm:mx-auto">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-y-8 gap-x-32 sm:gap-14 lg:gap-10 mx-16 justify-items-center">
        {data?.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fade bottom triggerOnce delay={200 * index} key={index}>
            <div>
              <div className="flex flex-col w-44 h-60 sm:w-56 sm:h-72 rounded-xl shadow-xl border border-gray-700 justify-center transform transition duration-500 hover:scale-105 bg-gray-800">
                <div className="flex justify-center xl:mb-5">
                  <img
                    src={item?.fields?.img?.fields?.file?.url}
                    alt="Team Member"
                    className="w-32 h-32 object-cover rounded-full grayscale"
                  />
                </div>
                <h2 className="text-white text-center text-xl">
                  {item?.fields?.fullName}
                </h2>
                <p className="font-light text-gray-400 text-center mb-3">
                  {language === "fi" ? (
                    <>{item?.fields?.fnPosition}</>
                  ) : (
                    <>{item?.fields?.enPosition}</>
                  )}
                </p>
              </div>
            </div>
          </Fade>
        ))}
      </div>
    </section>
  );
}
