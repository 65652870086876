/* eslint-disable operator-linebreak */
/* eslint-disable import/no-extraneous-dependencies */
// src/contentfulClient.js
import { createClient } from "contentful";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID || "lc64igc3me0g",
  accessToken:
    process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ||
    "7a75x8C3Jnb4GCYrPcEy6aV3DGDfhYc3zkoKJ7BtC3s",
});

export default client;
