/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from "react";

import Header from "parts/Header";
import { useParams } from "react-router-dom";
// import Footer from "parts/Footer";

import client from "api/client";
import ServiceDetail from "parts/ServiceDetail";
import Footer from "parts/Footer";

export const ServiceDetailPage = () => {
  const { id } = useParams();
  const [detailData, setDetailData] = useState([]);
  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessService",
      })
      .then((response) => {
        const details = response?.items.find((item) => item.sys.id === id);
        setDetailData([details]);
      })
      .catch(console.error);
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <ServiceDetail data={detailData.length === 1 ? [detailData[0]] : null} />
      <Footer />
    </>
  );
};
