/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import BusinessPartner from "../assets/images/Menu pic services HR Business Partner Interim 1.jpg";

const HRBusinessPartner = () => {
  const { language } = useContext(LanguageContext);

  const content = {
    en: {
      title: "HR Business Partner – Interim",
      intro:
        "Need HR support for a particular project or a transition period? Interim HRBP can offer needed resources from tackling complex HR issues and driving strategic initiatives to enhancing internal processes.",
      servicesTitle: "HRBP Service",
      services: [
        "Advisory for company management on HR issues",
        "Mediation in workplace disputes",
        "Support during changes like restructuring, cultural shifts, and new processes",
        "Strategy creation to attract, develop, and retain talent and manage hiring for key roles",
        "Development programs to build leadership or other skills",
      ],
      button: "Read More",
    },
    fi: {
      title: "HR Business Partner – Väliaikainen",
      intro:
        "Tarvitsetko HR-tukea tiettyyn projektiin tai siirtymävaiheeseen? Väliaikainen HRBP voi olla vaikuttava ja väliaikainen mahdollisuus monimutkaisten HR-ongelmien ratkaisemiseen ja sisäisten prosessien parantamiseen.",
      servicesTitle: "HRBP-palvelun sisältöesimerkkejä",
      services: [
        "Neuvonanto yritysjohdolle HR-asioissa",
        "Työpaikkariitojen sovittelu",
        "Tuki muutosten, kuten uudelleenjärjestelyjen, kulttuurimuutosten ja uusien prosessien aikana",
        "Strategioiden luominen osaamisen houkuttelemiseksi, kehittämiseksi ja säilyttämiseksi sekä avainroolien seuraajasuunnittelu",
        "Ohjelmien kehittäminen johtamistaitojen parantamiseksi organisaatiossa",
      ],
      button: "Lue lisää",
    },
  };

  const { title, intro, servicesTitle, services } = content[language];

  return (
    <div className="flex flex-col gap-12 lg:gap-20 lg:flex-row p-6 lg:p-24 text-black">
      {/* Right Section (Image) */}
      <div className="lg:w-1/2 flex justify-center lg:justify-end mt-8 lg:mt-0">
        <div className="relative w-full lg:w-auto lg:h-screen overflow-hidden">
          <img
            src={BusinessPartner}
            alt="HR Discussion"
            className="object-cover w-full h-full rounded-lg"
          />
        </div>
      </div>

      {/* Left Section */}
      <div className="lg:w-1/2 space-y-6 flex flex-col ">
        <h1 className="text-2xl lg:text-4xl font-bold">{title}</h1>
        <p className="text-base lg:text-xl">{intro}</p>
        <p className="font-semibold text-lg">{servicesTitle}</p>
        <ul className="list-disc text-sm lg:text-lg leading-8 lg:leading-10 ml-5 space-y-2">
          {services?.map((service, index) => (
            <li key={index}>{service}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HRBusinessPartner;
