/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import client from "api/client";
import React, { forwardRef, useContext, useEffect, useState } from "react";
import { LanguageContext } from "context/LanguageContext";

const AboutMe = forwardRef((props, ref) => {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .getEntries({
        content_type: "aboutme",
      })
      .then((response) => {
        if (response?.items.length > 0) {
          setData(response.items[0].fields);
        }
      })
      .catch(console.error);
  }, []);

  if (!data) {
    return <div className="text-white">Loading...</div>;
  }

  const aboutText =
    language === "en"
      ? "I am Inka, an effective communicator and experienced conflict mediator, with a history of helping teams reach understanding and resolve issues. If you’re looking into complex issues and need support in either implementation or building a strategy - let’s have a talk. I have wide ranging experience from HR; from mediation to strategy work, and I have worked in management teams in technology companies. I have led projects related to well-being at work, competence development, bonus systems, recruitment and employer image, and I have been closely involved in resolving disputes. Conflicts and problems interest me, and I am at my best in challenging interactions. I work naturally in Finnish and English."
      : "Olen Inka, tehokas viestijä ja kokenut konfliktien sovittelija, jolla on historia tiimien auttamisesta ymmärryksen saavuttamisessa ja ongelmien ratkaisemisessa. Jos sinulla on monimutkaisia asioita ja tarvitset tukea joko toteutuksessa tai strategian rakentamisessa – keskustellaan. Minulla on laaja kokemus HR-alueelta; sovittelusta strategiatyöhön, ja olen työskennellyt teknologiayritysten johtoryhmissä. Olen johtanut projekteja, jotka liittyvät työhyvinvointiin, osaamisen kehittämiseen, bonusjärjestelmiin, rekrytointiin ja työnantajamielikuvaan, ja olen ollut tiiviisti mukana konfliktien ratkaisussa. Konfliktit ja ongelmat kiinnostavat minua, ja olen parhaimmillani haastavissa vuorovaikutustilanteissa. Työskentelen sujuvasti sekä suomeksi että englanniksi.";

  const { name, email, phoneNo, img } = data;
  const imageUrl = img?.fields?.file?.url
    ? `https:${img.fields.file.url}`
    : "path_to_image.jpg"; // Fallback in case image URL is missing.

  return (
    <div
      className="bg-cover bg-center relative"
      ref={ref}
      style={{
        backgroundImage: `url(path_to_background_image.jpg)`, // Replace with actual background image
        backgroundAttachment: "fixed", // Parallax effect
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Main Content */}
      <div className="bg-[#1e1e1e] h-auto">
        <div className="px-6 lg:px-20 py-8 text-white lg:flex lg:items-center lg:justify-between">
          {/* About Me Text Section */}
          <div className="lg:w-1/2 space-y-4">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold">
              {language === "en" ? "About Me" : "Minusta"}
            </h2>
            <p className="text-sm md:text-base leading-8">{aboutText}</p>
          </div>

          {/* Image Section */}
          <div className="lg:w-1/2 w-full mt-8 lg:mt-0 lg:ml-8 flex justify-center lg:justify-end">
            <img
              src={imageUrl}
              alt={name}
              className="object-cover w-full max-w-md h-auto rounded-md lg:max-w-lg"
            />
          </div>
        </div>
        <hr className="border-gray-700 my-4" />
        <div className="flex flex-col md:flex-row md:justify-between space-y-2 md:space-y-0 px-6 lg:px-20 pb-5 text-white text-sm md:text-lg">
          <p className="font-bold">{name}</p>
          <p>{email}</p>
          <p>{phoneNo}</p>
        </div>
      </div>
       
    </div>
  );
});

export default AboutMe;
