/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-console */
/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */

import Service from "./Service";

export default function HeroPortfolio() {
  return (
    <>
      <Service />
    </>
  );
}
