/* eslint-disable max-len */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { LanguageContext } from "context/LanguageContext";
import Mediation from "../assets/images/Menu pic Mediation & Conflict Management .jpg";

export default function MediationSection() {
  const { language } = useContext(LanguageContext); // Accessing the current language

  // Define the content based on the selected language
  const content = {
    en: {
      title: "Mediation & Conflict Management",
      description: `Mediation is a conflict management method for resolving conflicts 
      based on a restorative concept of justice. Restorative justice perceives conflicts 
      as violations of relationships that can be restored. In workplace mediation, the 
      mediator helps the conflicting parties communicate and find a practical agreement 
      on how they can collaborate more effectively.`,
      process: `The process includes an information session on the mediation process, 
      one-on-one discussions with the parties affected by the conflict, a joint mediation 
      session, and a follow-up visit.`,
      steps: [
        "Initial assessment",
        "Information session",
        "Discussions with different parties to conflicts",
        "Joint mediation negotiation",
        "Agreement",
        "Follow-up visit",
      ],
      additionalInfo: `In addition to a mediation process, IMR can offer alternative 
      ways of handling conflicts, such as coaching or conflict management training.`,
      buttonText: "Read More",
    },
    fi: {
      title: "Välitys & Konfliktinhallinta",
      description: `Välitys on konfliktinhallintamenetelmä, joka perustuu palauttavaan oikeuden käsitteeseen. 
      Palauttava oikeus käsittää konfliktit suhteiden rikkomisina, jotka voidaan palauttaa. 
      Työpaikkavälityksessä välimies auttaa ristiriitaisissa osapuolissa kommunikoimaan ja löytämään 
      käytännöllisen sopimuksen siitä, miten he voivat tehdä yhteistyötä tehokkaammin.`,
      process: `Prosessi sisältää tietotilaisuuden välitysprosessista, henkilökohtaisia keskusteluja 
      konfliktin osapuolten kanssa, yhteisen välityssession ja seurantakäynnin.`,
      steps: [
        "Alkutason arviointi",
        "Tietotilaisuus",
        "Keskustelut eri konfliktin osapuolten kanssa",
        "Yhteinen välityspöytäkirja",
        "Sopimus",
        "Seurantakäynti",
      ],
      additionalInfo: `Välitysprosessin lisäksi IMR voi tarjota vaihtoehtoisia tapoja käsitellä 
      konflikteja, kuten coachingia tai konfliktinhallintakoulutusta.`,
      buttonText: "Lue lisää",
    },
  };

  return (
    <>
      <section className="container py-16 px-6 flex gap-10 flex-col md:flex-row items-center">
        {/* Left Column: Images */}
        <div className="w-full md:w-1/2 flex justify-center md:justify-end">
          <div className="relative w-full md:w-[500px] h-[300px] md:h-[600px]">
            {/* Top Image */}
            <img
              src={Mediation}
              alt="Image 1"
              className="absolute top-0 left-0 w-[100%]  md:w-[48%] h-[100%] object-cover object-left"
            />
            {/* Bottom Image */}
            <img
              src={Mediation}
              alt="Image 2"
              className="absolute bottom-0 right-0 w-[100%] md:w-[48%]  h-[100%] object-cover"
            />
          </div>
        </div>

        {/* Right Column: Text Content */}
        <div className="w-full md:w-1/2 md:pl-12 mt-6 md:mt-0">
          <h2 className="text-3xl md:text-4xl font-bold text-black mb-6">
            {content[language]?.title}
          </h2>
          <p className="text-lg text-gray-700 mb-4 leading-relaxed">
            {content[language]?.description}
          </p>
          <p className="text-lg text-gray-700 mb-4 leading-relaxed">
            {content[language]?.process}
          </p>
          <ul className="text-lg text-gray-700 mb-8 leading-relaxed list-disc list-inside">
            {content[language]?.steps.map((step) => (
              <li>{step}</li>
            ))}
          </ul>
          <p className="text-lg text-gray-700 mb-8 leading-relaxed">
            {content[language]?.additionalInfo}
          </p>
          {/* Read More Button */}
          {/* <a
          href="#"
          className="inline-flex items-center px-6 py-3 border border-gray-700 text-gray-700 hover:bg-gray-100 transition-all rounded-full text-lg"
        >
          {content[language].buttonText} <span className="ml-2">&rarr;</span>
        </a> */}
        </div>
      </section>
    </>
  );
}
