/* eslint-disable react/no-unescaped-entities */
/* eslint-disable linebreak-style */
/* eslint-disable import/extensions */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Fade from "react-awesome-reveal";
import Button from "elements/Button";
// import ContactUs from "./Contactus";
import HRBusinessPartner from "./HrBusinessPartner";
import Recurative from "./Recurative";
import CoachingSection from "./Coaching";
import MediationSection from "./MedationSection";

export default function ServiceDetail({ data }) {
  const [serviceId, setServiceId] = useState(null);

  useEffect(() => {
    // Extract the service ID from the URL
    const url = window.location.href;
    const id = url.split("/").pop();
    setServiceId(id);
  }, []);

  // Display a message if the service ID is not found
  if (data === null) {
    return (
      <section className="container mx-auto">
        <Fade bottom triggerOnce>
          <div className="flex flex-col w-full justify-center">
            <div className="flex w-full justify-center">
              <img
                src="https://photographylife.com/wp-content/uploads/2022/06/Unsuccessful-black-and-white-edit-of-a-blue-photo.jpg"
                alt="Not Found"
                className="sm:w-3/4 xl:w-5/12 mt-5 filter grayscale"
              />
            </div>
            <h1 className="text-black text-2xl text-center mt-5">
              Service Not Found
            </h1>
            <div className="flex justify-center">
              <Button
                href="/services"
                type="link"
                className="flex w-30 h-10 px-5 mt-5 bg-gray-800 text-white items-center rounded transform transition duration-500 hover:bg-gray-900"
              >
                Go Back
              </Button>
            </div>
          </div>
        </Fade>
      </section>
    );
  }

  return (
    <section>
      {serviceId === "3soxduAepvw0DQ4rURK6Cc" && (
        <>
          <Recurative />
        </>
      )}
      {serviceId === "JK04aRLE1bfygfoiTHTi7" && (
        <>
          <CoachingSection />
        </>
      )}
      {serviceId === "1QHGzjzshbwJ1vZVlUnDcp" && (
        <>
          <HRBusinessPartner />
        </>
      )}
      {serviceId === "15DSJzZ75PPeIiWtiXbPEO" && (
        <>
          <MediationSection />
        </>
      )}

      {/* <ContactUs /> */}
    </section>
  );
}
