/* eslint-disable linebreak-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from "react";

import Header from "parts/Header";
import HeroPortfolio from "parts/ServicePage";
import ContactUs from "parts/Contactus";
import Footer from "parts/Footer";
// import Footer from "parts/Footer";

export default class ServicePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Header />
        <HeroPortfolio />
        <Footer />
      </>
    );
  }
}
