/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { EffectCards, Navigation, Pagination, Autoplay } from "swiper/modules";
import company1 from "../assets/images/Slider/Company description pic carusell 1.jpg";
import company2 from "../assets/images/Slider/Company description pic carusell 2.jpg";
import company3 from "../assets/images/Slider/Company description pic carusell 5.jpg";
import company4 from "../assets/images/Slider/Company description pic carusell 6.jpg";
import company5 from "../assets/images/Slider/Company description pic carusell 9.jpg";
import company6 from "../assets/images/Slider/Company description pic carusell 14.jpg";

const ImageSwiper = () => {
  const images = [company1, company2, company3, company4, company5, company6];

  return (
    <div className="py-20">
      <div className="image-swiper-container px-4 sm:px-14 md:px-40">
        <Swiper
          effect="cards"
          grabCursor
          loop
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          modules={[EffectCards, Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          className="mySwiper"
        >
          {images?.map((image, index) => (
            <SwiperSlide key={index}>
              <div className="image-container">
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className="slide-image w-full h-auto object-cover rounded-lg"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ImageSwiper;
