/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useContext, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageToggleMap from "components/LangageToolge";
import { LanguageContext } from "context/LanguageContext";
import client from "api/client";
import Button from "../elements/Button";

export default function Header({
  onServiceClick,
  onClickAboutMe,
  handleScrollToContactUs,
}) {
  const [isCollapse, setIsCollapse] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname;
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  const navItems = {
    en: [
      { name: "Home", path: "/" },
      { name: "About Me", onClick: onClickAboutMe },
      {
        name: "Services",
        onClick: () => {
          if (path === "/") {
            onServiceClick();
          } else {
            navigate("/services");
          }
        },
        path: "/services", // Add path here to check active status
      },
      { name: "Contact Us", onClick: handleScrollToContactUs },
    ],
    fi: [
      { name: "Koti", path: "/" },
      { name: "Tietoja minusta", onClick: onClickAboutMe },
      {
        name: "Palvelut",
        onClick: () => {
          if (path === "/") {
            onServiceClick();
          } else {
            navigate("/services");
          }
        },
        path: "/services",
      },
      { name: "Ota yhteyttä", onClick: handleScrollToContactUs },
    ],
  };

  const items = navItems[language];

  useEffect(() => {
    client
      .getEntries({
        content_type: "growBusinessLandingPage",
      })
      .then((response) => {
        setData(response?.items[0]?.fields);
      })
      .catch(console.error);
  }, []);

  return (
    <header className="bg-white shadow-lg transition-transform duration-300 ease-in-out">
      <div className="flex justify-between items-center py-4 px-4 lg:px-20">
        <div className="flex w-full justify-between px-4 lg:px-0">
          <div>
            <img
              src={data?.logo?.fields?.file?.url}
              alt="Logo"
              className="w-12 rounded-full cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="flex gap-5">
            <div className="md:hidden">
              <LanguageToggleMap />
            </div>
            <button
              className="block text-black lg:hidden focus:outline-none"
              onClick={() => setIsCollapse(!isCollapse)}
              aria-expanded={isCollapse}
              aria-controls="dropdown-menu"
            >
              <svg
                className="w-8 h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  className={`${isCollapse ? "hidden" : "block"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
                <path
                  className={`${!isCollapse ? "hidden" : "block"}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Desktop Navigation */}
        <ul className="hidden text-black items-center lg:flex flex-row space-x-4 mt-0">
          {items.map((item, index) => (
            <li key={index} className="py-2 lg:py-0">
              {item.onClick ? (
                <button
                  className={`${
                    path === item?.path ? "active-link font-extrabold" : ""
                  } uppercase text-md px-4 whitespace-nowrap transition duration-300 ease-in-out no-underline hover:underline`}
                  onClick={item.onClick}
                >
                  {item.name}
                </button>
              ) : (
                <Button
                  className={`${
                    path === item?.path ? "active-link font-extrabold" : ""
                  } uppercase text-md px-4 whitespace-nowrap transition duration-300 ease-in-out no-underline hover:underline`}
                  type="link"
                  href={item?.path}
                >
                  {item?.name}
                </Button>
              )}
            </li>
          ))}
          <LanguageToggleMap />
        </ul>

        {/* Mobile Dropdown Menu */}
        <Transition
          show={isCollapse}
          enter="transition-opacity duration-400"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-400"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ul
            className="absolute z-50 top-10 flex flex-col text-black tracking-widest my-6 bg-white border-b-2 border-gray-300 right-0 left-0 py-4 px-4 lg:hidden"
            id="dropdown-menu"
          >
            {items.map((item, index) => (
              <li key={index} className="py-2 bg-white">
                {item.onClick ? (
                  <button
                    className={`${
                      path === item?.path ? "active-link font-extrabold" : ""
                    } font-medium px-10 no-underline hover:underline`}
                    onClick={item.onClick}
                  >
                    {item.name}
                  </button>
                ) : (
                  <Button
                    className={`${
                      path === item?.path ? "active-link font-extrabold" : ""
                    } font-medium px-10 no-underline hover:underline`}
                    type="link"
                    href={item?.path}
                  >
                    {item?.name}
                  </Button>
                )}
              </li>
            ))}
          </ul>
        </Transition>
      </div>
    </header>
  );
}
