/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import { Fade } from "react-awesome-reveal";
import { LanguageContext } from "context/LanguageContext";
import Button from "../elements/Button";

export default function Hero({ data, handleScrollToContactUs }) {
  const { language } = useContext(LanguageContext);

  return (
    <section
      className="relative w-full h-[100vh] flex items-center justify-center lg:justify-start px-4 lg:px-20 bg-cover bg-center"
      style={{
        backgroundImage: `url(${data?.headerImage?.fields?.file?.url})`,
        backgroundAttachment: "fixed", // Parallax effect
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50" />

      {/* Content */}
      <div className="relative z-10 w-full lg:w-1/2 xl:pl-12 sm:px-4 text-center lg:text-left">
        <h1 className="text-4xl sm:text-6xl text-white font-bold leading-tight mb-5">
          {language === "fi" ? data?.fiHeader : data?.enHeaderTitle}
        </h1>

        <p className="font-light text-lg text-gray-300 leading-relaxed mb-16">
          {language === "fi" ? data?.fiDes : data?.enHeaderDes}
        </p>

        <Fade direction="up" delay={500} triggerOnce>
          <Button
            onClick={handleScrollToContactUs}
            type="link"
            className="inline-flex w-auto h-auto items-center px-6 py-4 text-white text-xl bg-[#212529] rounded-lg shadow-2xl hover:bg-[#121619] transition duration-200"
          >
            {language === "fi" ? "Ota yhteyttä" : "Contact Us"}
            <svg
              className="ml-2 w-7 h-7 text-white animate-bounce-x"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </Button>
        </Fade>
      </div>
    </section>
  );
}
