// src/components/LanguageToggle.js
import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

const LanguageToggle = () => {
  const { language, switchLanguage } = useContext(LanguageContext);

  const handleLanguageChange = (event) => {
    switchLanguage(event.target.value);
  };

  return (
    <div className="relative inline-block text-left">
      <select
        value={language}
        onChange={handleLanguageChange}
        className="flex items-center justify-center w-8 h-8 text-sm font-medium text-white bg-black border border-transparent rounded-full shadow-sm appearance-none focus:outline-none"
        style={{
          WebkitAppearance: "none",
          MozAppearance: "none",
          padding: "0", // Ensure no extra padding disturbs centering
          textAlign: "center", // Center the text
        }}
      >
        <option value="en" className="text-black">
          EN
        </option>
        <option value="fi" className="text-black">
          FI
        </option>
      </select>
    </div>
  );
};

export default LanguageToggle;
